import * as React from 'react'
import { graphql, PageProps } from 'gatsby'
import {
	Helmet,
	I18nextContext,
	useI18next,
	useTranslation,
} from 'gatsby-plugin-react-i18next'
import type { AboutUsQuery } from '@graphql-types'
import { css, useTheme } from '@emotion/react'
import { useContext, useEffect } from 'react'
import SEO from '@components/SEO'
import Container from '@components/Container'
import GoogleMapReact from 'google-map-react'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { maxWidth, minWidth } from 'src/themes'
import StaffComponent from '@components/StaffComponent'
import styled from '@emotion/styled'
import { H2Highlight } from '@components/H2Highlight'

const LogoHub = styled.div`
	display: flex;
	max-width: 1024px;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;

	> div {
		margin: 1rem;
	}
`
const AboutUsPage = (props: PageProps<AboutUsQuery>) => {
	const { t } = useTranslation()
	const theme = useTheme()

	return (
		<Container>
			<SEO title={t('layout:about_us')} keywords={[]} />
			<h1>{t('tecky_academy')}</h1>
			<p>{t('aboutus.description')}</p>
			<H2Highlight>{t('aboutus.our_location')}</H2Highlight>
			<div
				css={css`
					display: flex;
					flex-wrap: wrap;
					width: 100%;
					justify-content: center;
				`}>
				{props.data.locations.nodes.map((location) => (
					<div
						key={location._id}
						css={css`
							display: flex;
							flex-direction: column;
							align-items: stretch;
							padding: 1rem;
							box-sizing: border-box;
							width: 50%;

							${maxWidth[2]} {
								width: 100%;
								flex: none;
							}
						`}>
						<hgroup>
							<h3>
								<span
									className={`fi fi-${location.country}`}></span>{' '}
								{location.name}
							</h3>
							{location.address1title && (
								<h4>{location.address1title}</h4>
							)}
						</hgroup>
						<p>
							{location.address1a}
							<br />
							{location.address1b}
						</p>
						{location.address2b != null && (
							<p>
								{location.address2a}
								<br />
								{location.address2b}
							</p>
						)}
						<div
							css={css`
								flex: 1;
							`}
						/>
						<div
							css={css`
								width: 100%;
								min-height: 400px;
								margin-bottom: 2rem;
							`}>
							<GoogleMapReact
								bootstrapURLKeys={{
									key: process.env.GATSBY_GOOGLE_MAP_API!,
								}}
								defaultCenter={{
									lat: location.location!.lat!,
									lng: location.location!.lng!,
								}}
								defaultZoom={17}>
								<img
									style={{
										position: 'absolute',
										transform: 'translate(-50%, -100%)',
									}}
									alt={location.name}
									src={
										props.data.marker!.childImageSharp!
											.fluid!.src
									}
									// @ts-ignore
									lat={location.location!.lat}
									// @ts-ignore
									lng={location.location!.lng}
								/>
							</GoogleMapReact>
						</div>
					</div>
				))}
			</div>
			<H2Highlight>{t('aboutus.our_awards')}</H2Highlight>
			<LogoHub
				css={css`
					img {
						filter: ${theme.imageFilter};
					}
				`}>
				<StaticImage
					src='../images/caring_company_logo.png'
					width={150}
					placeholder='blurred'
					alt={t('layout:caring_company')}
				/>
				<StaticImage
					src='../images/tquk_approved_logo.png'
					width={150}
					placeholder='blurred'
					alt={t('layout:tquk_approved_center')}
				/>
				<StaticImage
					src='../images/reimagine_education_challenge.png'
					width={150}
					placeholder='blurred'
					alt={t('layout:reimagine_education_challenge')}
				/>
			</LogoHub>
			<H2Highlight>{t('aboutus.our_media_exposure')}</H2Highlight>
			<LogoHub
				css={css`
					img {
						filter: ${theme.imageFilter};
					}
				`}>
				{props.data.medias.nodes.map((mediaLogo, i) => (
					<div key={i}>
						<GatsbyImage
							image={
								mediaLogo?.mediaLogoSharp?.childImageSharp
									?.gatsbyImageData
							}
							alt={mediaLogo.title}
						/>
					</div>
				))}
			</LogoHub>
			<H2Highlight>{t('aboutus.our_partners')}</H2Highlight>
			<LogoHub
				css={css`
					background-color: white;
				`}>
				{props.data.partners.nodes.map((partnerLogo, i) => (
					<div key={i}>
						<GatsbyImage
							image={
								partnerLogo?.logoSharp?.childImageSharp
									?.gatsbyImageData
							}
							alt={partnerLogo.name!}
						/>
					</div>
				))}
			</LogoHub>
			<H2Highlight>{t('aboutus.our_team')}</H2Highlight>
			<div
				css={css`
					display: flex;
					flex-wrap: wrap;
				`}>
				{props.data.staff.nodes.map((staff, i) => (
					<StaffComponent
						key={staff._id}
						staff={staff}></StaffComponent>
				))}
			</div>
		</Container>
	)
}

export default AboutUsPage

export const query = graphql`
	query AboutUs($language: String!) {
		...TranslationFragment
		medias: allCockpitMediaLogosCollection(
			filter: { _lang: { eq: $language } }
		) {
			nodes {
				title
				mediaLogo {
					path
				}
				mediaLogoSharp {
					childImageSharp {
						gatsbyImageData(width: 80, placeholder: BLURRED)
					}
				}
			}
		}
		marker: file(relativePath: { eq: "map_marker.png" }) {
			childImageSharp {
				fluid(maxWidth: 50) {
					src
				}
			}
		}
		partners: allCockpitPartnersCollection(
			filter: { _lang: { eq: $language } }
		) {
			nodes {
				_id
				name
				logo {
					path
				}
				logoSharp {
					childImageSharp {
						gatsbyImageData(width: 150, placeholder: BLURRED)
					}
				}
			}
		}
		staff: allCockpitStaffCollection(
			filter: { active: { eq: true }, _lang: { eq: $language } }
			sort: { fields: _o, order: ASC }
		) {
			nodes {
				_id
				name
				description
				title
				specialities
				profileImg {
					path
				}
				available_countries
				profileImgSharp {
					childImageSharp {
						gatsbyImageData(
							width: 250
							height: 250
							placeholder: BLURRED
						)
					}
				}
			}
		}
		locations: allCockpitLocationsCollection(
			filter: { _lang: { eq: $language } }
			sort: { fields: _o, order: ASC }
		) {
			nodes {
				_id
				address1title
				address1b
				address1a
				address2b
				address2a
				country
				name
				gallery {
					path
				}
				location {
					lat
					lng
				}
			}
		}
	}
`
